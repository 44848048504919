.arrow svg path {
  transition: 0.2s all;
}

.arrow:hover svg path {
  stroke: #0d8bd1;
}
.arrow.right {
  transform: rotate(180deg);
}
.arrow.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.arrow {
  border: none;
  background-color: transparent;
  outline: none;
  width: 48px;
  height: 48px;
}
