.services {
  padding-top: 100px;
}
.service_buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.service-btn {
  padding: 8px 28px;
  border-radius: 20px;
  color: white;
  border: 1px solid white;
  transition: 0.2s all;
  background-color: transparent;
  outline: none;
}
.service-btn:hover {
  color: #46b6f3;
  border-color: #46b6f3;
}
.service-btn.active {
  color: #0d8bd1;
  border-color: #0d8bd1;
}
.arrows {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .services {
    padding-top: 40px;
  }
  .services h3 {
    margin-bottom: 30px;
  }
}

@media (max-width: 515px) {
  .service_buttons button{
    font-size: 14px;
  }
}

@media (max-width: 434px) {
  .service_buttons button{
    font-size: 12px;
    padding: 6px 21px;
  }
}

@media (max-width: 362px) {
  .service_buttons button{
    font-size: 11px;
    padding: 4px 14px;
  }
}

@media (max-width: 311px) {
  .service_buttons {
    gap: 10px;
  }
  .service_buttons button{
    font-size: 10px;
    padding: 3px 10px;
  }
}