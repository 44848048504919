.form-section {
  padding-top: 150px !important;
  position: relative;
  margin-bottom: 100px !important;
}
.goodRequest {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  height: 50%;
  justify-content: center;
  align-items: center;
  padding: 30px;
  z-index: 15;
  background-color: #202020f5;
  border-radius: 10px;
  display: none;
}
.showMsg {
  display: flex;
  animation: opacity 4s linear forwards;
}
@keyframes opacity {
  from {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.goodRequest b {
  font-size: 20px;
  white-space: pre-line;
}

.form-section h3 {
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;
}
.form-section > p {
  text-align: center;
  margin-bottom: 40px;
}
.form-section .fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-section form {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.form-section form input,
.form-section form textarea {
  padding: 27px 20px 4px 20px;
  font-size: 14px;
  line-height: 24px;
  border: none;
  outline: none !important;
  border-bottom: 1px solid white;
  /* height: 24px; */
  width: 100%;
  color: white;
  background-color: transparent;
}
.form-section form textarea {
  min-height: 56px;
  max-width: 100%;
  min-width: 100%;
}
.form-section form input::placeholder,
.form-section form textarea::placeholder {
  color: white;
}
.form-section form input:focus,
.form-section form textarea:focus {
  border-color: #0d8bd1;
}
.form-section form .button {
  margin-top: 20px;
}

@media screen and (max-width: 550px) {
  .form-section h3 {
    font-size: 30px;
  }
  .form-section p {
    font-size: 14px;
  }

}
