.technologies__card {
    max-width: 145px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0 27px 0;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.technologies__card-img {
    width: 60px;
    height: 60px;
}
.technologies__card-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #251d7b;
    margin-top: 20px;
}
/*.technologies__card:hover {*/
/*    background: #251d7b;*/
/*    -webkit-box-shadow: 0px 0px 90px rgba(64, 48, 255, 0.25);*/
/*    box-shadow: 0px 0px 90px rgba(64, 48, 255, 0.25);*/
/*}*/
/*.technologies__card:hover .technologies__card-text {*/
/*    color: #fff;*/
/*}*/
/*.technologies__card:hover > .technologies__card-img > .technologies-img {*/
/*    -webkit-filter: invert(100%) sepia(0%) saturate(1415%) hue-rotate(261deg)*/
/*    brightness(150%) contrast(107%);*/
/*    filter: invert(100%) sepia(0%) saturate(1415%) hue-rotate(261deg)*/
/*    brightness(150%) contrast(107%);*/
/*}*/


@media screen and (max-width: 870px) {
    .technologies__card {
        width: 116px;
        height: 116px;
    }

    .technologies__card-img {
        width: 40px;
        height: 40px;
    }

    .technologies__card-text {
        font-size: 12px;
    }
}