@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #111213;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1140px;
  width: 90%;
  margin: 0 auto;
}

.container_width {
  max-width: 1200px;
}
.container_wide {
  max-width: 1300px;
  margin: 0 auto;
}

.btn-hover:hover {
  box-shadow: 0 0 90px rgba(13, 139, 209, 0.15);
}

*::after,
*::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
ul[class] {
  list-style: none;
}
img {
  max-width: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}

.margin-none {
  margin: 0;
}

.button {
  padding: 12px 50px;
  background-color: #0d8bd1;
  border-radius: 20px;
  font-size: 20px;
  display: inline-block;
  outline: none;
  border: none;
  color: white;
  font-weight: 400;
  user-select: none;
  transition: 0.2s all;
}
.button:hover {
  cursor: pointer;
  background-color: #0d62d1;
}
.button:active {
  background-color: #0d2dbd;
}

.slick-track{
  display: flex;
  gap: 20px;
  height: 100%;
}

.slick-slide{
  height: auto;
}

.slick-slide > div {
  height: 100%;
}

.projects__slide{
  height: 100%;
}
