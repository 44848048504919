@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Orbitron:wght@400;500;600;700;800;900&family=Red+Hat+Display:wght@700&family=Roboto:wght@100;300;700;900&display=swap');

.error__container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
}

.robot{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.desc{
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-right: 3%;
}

.desc div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
}


.error__404{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-size: 150px;
    font-weight: 700;
    color: #F3F3F3;
}

.error__desc{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #F3F3F3;
}

.error__button{
    padding: 10px 50px;
    background: #0D8BD1;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    
    .desc{
        right: 100px;
        padding: 2%;
    }

    .error__404{
        font-size: 120px;
    }
    
    .error__desc{
        font-size: 24px;
    }

}

@media screen and (max-width: 715px){

    .robot{
        position: absolute;
        left: 0;
        top: 25%;
        width: 45%;
    }

    .error__container{
        align-items: center;
    }

    .desc{
        width: 70%;
        height: 70%;
        min-height: 400px;
        justify-content: space-between;
    }
    .desc div{
        width: 100%;
    }
    
    .error__404{
        justify-content: end;
        font-size: 90px;
        align-self: flex-end;

    }
    
    .error__desc{
        align-self: flex-end;
        font-size: 20px;
    }
}

@media screen and (max-width: 555px) {
    .robot{
        top: 30%;
        width: 260px;
        height: 40%;
    }

    .desc div{
        gap: 60px;
    }

    .desc{
        width: 85%;
        height: 60%;
    }


    .error__404{
        font-size: 52px;
    }
    
    .error__desc{
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {
    .desc{
        width: 85%;
        height: 60%;
        min-height: 300px;
    }

    .robot{
        top: 25%;
        width: 60%;
    }

    .desc div{
        gap: 35px;
    }
}




