.dropDown {
  position: relative;
}
.dropDown_placeholder {
  cursor: pointer;
  display: flex;
  padding: 5px 10px 4px 20px;
  align-items: flex-end;
  height: 56px;
  color: white;
  border-bottom: 1px solid white;
  justify-content: space-between;
  min-width: max-content;
}
.dropDown_placeholder input {
  padding: 0 !important;
  border: none !important;
  height: 24px;
  pointer-events: none;
  cursor: pointer !important;
}
.dropDown_placeholder p {
  color: white;
  font-size: 14px;
  line-height: 24px;
}
.dropDown_placeholder img {
  width: 24px;
  height: 24px;
  transition: 0.2s all;
}
.select {
  transition: 0.2s all;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 20px;
  padding-bottom: 15px;
  position: absolute;
  top: 56px;
  z-index: 10;
  background-color: #202020ec;
  border-radius: 10px;
}

.option {
  color: white;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  transition: 0.2s all;
  border-bottom: 1px solid transparent;
}
.option:hover {
  border-bottom: 1px solid white;
}
