html,
body {
  height: 100%;
  background: #111213;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  scroll-behavior: smooth;
}
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}
.main {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.title {
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  text-align: center;
  margin-bottom: 70px;
}

.aboutus {
  margin-top: 60px;
  margin-bottom: 100px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.aboutus__title {
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  max-width: 561px;
  width: 100%;
  margin-top: 76px;
}
.aboutus__desc {
  font-weight: 500;
  max-width: 521px;
  width: 100%;
  margin-top: 33px;
  margin-bottom: 92px;
}
.aboutus__btn {
  font-size: 20px;
  line-height: 24px;
  background: #0d8bd1;
  border-radius: 20px;
  padding: 12px 50px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.aboutus__btn:hover {
  box-shadow: 0px 0px 90px rgba(13, 139, 209, 0.15);
}

.aboutus__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.aboutus__img {
  height: 555px;
  width: 555px;
  background: url("./../../assets/aboutus-img.svg") no-repeat;
  background-size: cover;
}

.project-slider {
  width: 100%;
}

/* .slick-prev::before,
.slick-next::before {
  display: none;
} */

.technologies {
  padding-top: 150px;
}

/*.technologies__cards-cont{*/
/*  max-width: 900px;*/
/*  max-height: 280px;*/
/*  overflow-x: auto;*/
/*}*/

.technologies__cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}

.projects {
  margin-top: 150px !important;
  padding-bottom: 100px;
}

/* .projects__slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 50px;
  height: 700px;
} */

.stages {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
  align-items: flex-end;
  margin-top: 150px !important;
}
.stages h3 {
  align-self: center;
  margin-bottom: 20px;
}
.stage-line {
  width: 4px;
  height: 750px;
  background-color: #c4c4c4;
  position: absolute;
  top: 175px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.stage {
  width: 50%;
  display: flex;
  position: relative;
  gap: 30px;
  left: -45px;
  text-align: start;
  height: 100px;
  align-items: center;
}
.stage-left {
  align-self: flex-start;
  flex-direction: row-reverse;
  text-align: end;
  left: 15px;
}
.stage-number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
/*  */
.stage-number p {
  color: #0d8bd1;
  font-size: 30px;
  width: 20px;
  text-align: center;
}
.stage-circle {
  width: 30px;
  height: 30px;
  border: 8px solid #0d8bd1;
  border-radius: 50%;
  background-color: #111213;
}
.stage-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: -22;
  transition: all 0.3s;
}
.popup__active {
  z-index: 999;
  opacity: 1;
}
.popup__content {
  padding: 32px 30px;
  background: #111213;
  border-radius: 4px;
  max-width: 451px;
  width: 100%;
  z-index: 999;
  transition: all 0.3s;
  position: relative;
}
.popup__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.popup__close {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
}
.popup__input {
  outline: none;
  border: none;
  background: none;
  border-bottom: 1px solid #fff;
  padding: 13px 16px;
  margin-bottom: 14px;
  color: #fff;
}

.popup__input:-webkit-autofill,
.popup__input:-webkit-autofill:hover,
.popup__input:-webkit-autofill:focus {
  border-bottom: 1px solid #fff;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-size-adjust: inherit;
  box-shadow: 0 0 0px 1000px #111213 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.popup__input::-webkit-outer-spin-button,
.popup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.popup__input[type="number"] {
  -moz-appearance: textfield;
}
.popup__input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #c4c4c4;
}
.popup__input::-moz-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__input:-ms-input-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__input::-ms-input-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__input::placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__area {
  resize: none;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  color: #fff;
  padding: 13px 16px;
  height: 124px;
  border-bottom: 1px solid #fff;
  margin-bottom: 140px;
}
.popup__area::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__area::-moz-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__area:-ms-input-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__area::-ms-input-placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__area::placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.popup__btn {
  outline: none;
  border: none;
  background: none;
  font-size: 15px;
  line-height: 18px;
  background: #0d8bd1;
  border-radius: 10px;
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
}
.popup__link {
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #0d8bd1;
  max-width: 273px;
  width: 100%;
  margin-top: 33px;
}
.popup__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.466);
}

.success {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.success-inactive {
  display: none;
}

.success-modal {
  position: fixed;
  top: 8%;
  height: 577px;
  width: 451px;
  z-index: 999;
  background-color: #111213;
  border-radius: 10px;
}

.success-modal__callout {
  margin-top: 45px;
}

.success-modal__message1 {
  position: absolute;
  margin-top: 24px;
  top: 12%;
  left: 15%;
  font-size: 20px;
  width: 160px;
  color: #e1e1ed;
}

.success-modal__message2 {
  position: absolute;
  top: 28%;
  left: 15%;
  font-size: 20px;
  width: 190px;
  color: #e1e1ed;
}

.success-modal__message1-en {
  position: absolute;
  margin-top: 24px;
  top: 12%;
  left: 15%;
  font-size: 20px;
  width: 250px;
  color: #e1e1ed;
}

.success-modal__message2-en {
  position: absolute;
  top: 28%;
  left: 15%;
  font-size: 20px;
  width: 200px;
  color: #e1e1ed;
  color: #e1e1ed;
}

.sampleNewNextArrow {
  right: 45%;
  position: absolute;
  bottom: -60px;
}
.sampleNewPrevArrow {
  position: absolute;
  bottom: -60px;
  left: 45%;
  transform: rotate(180deg);
}

@media screen and (max-width: 1150px) {
  .sampleNewNextArrow {
    display: none !important;
  }
  .sampleNewPrevArrow {
    display: none !important;
  }
}

@media screen and (max-width: 1033px) {
  .aboutus {
    flex-direction: column;
    align-items: center;
    /* height: 900px; */
  }

  .aboutus__left {
    z-index: 25;
  }

  .aboutus__right {
    z-index: 1;
    position: absolute;
    flex-direction: column;
  }
  .aboutus__img {
    transform: translateY(-30px);
  }

  .aboutus__social-item {
    margin-right: 45px;
  }
  .projects {
    margin-top: 100px !important;
  }
}

@media screen and (max-width: 870px) {
  .title {
    font-size: 30px;
  }
  .aboutus {
    height: auto;
  }
  .aboutus__title {
    font-size: 34px;
  }
  .aboutus__desc {
    width: 430px;
  }
  .technologies {
    padding-top: 40px;
  }

  .reviews__text {
    font-size: 15px;
  }
  .reviews__autor {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {

  .contact__div{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .stages {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;
    align-items: flex-start;
    margin-top: 70px !important;
  }
  .stages h3 {
    align-self: center;
    margin-bottom: 0;
  }
  .stage-line {
    width: 4px;
    height: 770px;
    background-color: #c4c4c4;
    position: absolute;
    top: 135px;
    left: 43px;
    right: auto;
    margin: 0;
  }
  .stage {
    width: 100%;
    display: flex;
    position: relative;
    gap: 30px;
    text-align: start;
    height: 100px;
    left: 0;
    align-items: center;
    align-self: flex-start;
  }
  .stage-left {
    flex-direction: row;
    left: 0;
  }
  .stage-number {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  /*  */
  .stage-number p {
    color: #0d8bd1;
    font-size: 30px;
    width: 20px;
    text-align: center;
  }
  .stage-circle {
    width: 30px;
    height: 30px;
    border: 8px solid #0d8bd1;
    border-radius: 50%;
    background-color: #111213;
  }
  .stage-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .projects {
    margin-top: 50px !important;
  }

}

@media screen and (max-width: 530px) {
  .aboutus__title {
    font-size: 30px;
  }
  .aboutus__desc {
    font-size: 15px;
    width: 80%;
  }
  .aboutus__img {
    width: 100%;
    aspect-ratio: 1/1 !important;
    height: auto !important;
    max-width: 340px;
    max-height: 340px !important;
    transform: translateY(0);
  }
  .aboutus__btn {
    font-size: 15px;
  }

  .aboutus__right {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .aboutus__social {
    transform: translateY(-200px);
  }

  .title {
    font-size: 25px;
  }

  .connection {
    background: none;
    padding: 0px 0 260px 0 !important;
  }
  .connection__input {
    font-size: 12px;
    padding: 16px 230px 17px 32px;
  }
  .connection__input::placeholder {
    width: 100px;
  }
  .connection__btn {
    font-size: 14px;
    padding: 6px 34px;
    top: 10px;
  }

  .technologies {
    background: none;
  }
}

@media screen and (max-width: 437px) {
  .stage-info p,
  .stage-info b {
    font-size: 14px;
  }
  .stage-info {
    gap: 10px;
  }
}

@media screen and (max-width: 389px) {
}
