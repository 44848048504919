
.sidebar {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    max-width: 375px;
    z-index: 999;
    width: 100%;
    transition: all .5s;
    transform: translateX(0px);
}


.sidebar.inactive {
    transform: translateX(375px);
    opacity: 0;
    transition: all .5s;
    display: none;
}

.sidebar__menu {
    display: block;
    background-color: #111213;
    max-width: 375px;
    width: 100%;
    z-index: 999;
    overflow: visible;
}

.sidebar__header {
    display: flex;
    justify-content: space-between;
    padding: 27px 16px;
    margin-bottom: 35px;
}

.sidebar__header-close {
    cursor: pointer;
}

.sidebar__links {
    display: flex;
    flex-direction: column;
}

.sidebar__links-elem {
    padding: 16px;
    border-bottom: 1px solid #443E3E;
}
.sidebar__lang {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #443E3E;
    margin-bottom: 32px;
}

.sidebar__lang-elem {
    cursor: pointer;
    margin: 5px;
    color: #999999;
}

.sidebar__lang-elem-active {
    cursor: pointer;
    margin: 5px;
    color: #ffffff;
}

.sidebar__socials {
    display: flex;
    flex-direction: column;
}

.sidebar__socials-title {
    margin: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #999999;
}
.sidebar__socials-link {
    padding: 16px;
}






.sidebar__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.466);
    z-index: 998;
}