.projects__slide {
  display: grid;
  grid-template-columns: auto 45%;
  align-items: flex-start;
  text-align: start;
  background-color: #202020;
  border-radius: 10px;
}
.projects h3 {
  margin-bottom: 50px !important;
}

.projects__left {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.projects__left ul {
  padding-left: 20px;
}
.projects__left > h4 {
  font-size: 20px;
  font-weight: 700;
}
.projects__types {
  display: flex;
  gap: 10px 15px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.project_type {
  padding: 4px 14px;
  background: transparent;
  color: white;
  font-size: 14px;
  border: 1px solid #0d8bd1;
  border-radius: 20px;
}
.project_team {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.projects__right.tablet {
  display: none;
}
.projects__right {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}
.projects__right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 950px) {
  .projects__slide {
    grid-template-columns: 1fr;
  }
  .projects__right.main {
    display: none;
  }
  .projects__right.tablet {
    display: flex; 
  }
  .block-for-tablet {
    display: grid;
    /* grid-template-columns: auto 45%; */
    grid-template-columns: 1fr 2fr;
    gap: 10px;
  }

}
@media (max-width: 768px) {

  .block-for-tablet {
    grid-template-columns: 1fr;
  }
  .projects h3 {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 600px) {
  
  .projects__right.tablet {
    display: none;
  }
  .projects__right.main {
    display: flex;
    aspect-ratio: 1 / 1;
    margin-top: auto;
  }
  .block-for-tablet {
    grid-template-columns: 1fr;
  }
  .block-for-tablet ul li {
    font-size: 16px;
  }
  .project_description {
    font-size: 14px;
  }
}
