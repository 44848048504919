.footer {
    width: 100%;
    background: #0a0a0a;
    padding-top: 50px;
}
.footer__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 36px;
}
.footer__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer__logo {

    width: 230px;
}
.footer__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 2px solid #fff;
    padding-bottom: 24px;
}
.footer__social-item {
    margin-right: 36px;
}
.footer__social-item:last-child {
    margin-right: 0;
}
.footer__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 32px;
}
.footer__link {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 16px;
    transition: all .2s;
}
.footer__link:hover {
    color: #c4c4c4;
}
.footer__link:last-child {
    margin-bottom: 0;
}
.footer__tel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}
.footer__tel span {
    margin-right: 16px;
}
.footer__mail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}
.footer__mail span {
    margin-right: 16px;
}
.footer__place {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.footer__place span {
    margin-right: 16px;
}
.footer__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 32px;
}
.footer__input {
    max-width: 230px;
    width: 100%;
    border: none;
    outline: none;
    background: none;
    border-bottom: 1px solid #fff;
    padding: 7px 18px;
    color: #fff;
}
.footer__input::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}
.footer__input::-moz-placeholder {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}
.footer__input:-ms-input-placeholder {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}
.footer__input::-ms-input-placeholder {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}
.footer__input::placeholder {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}
.footer__btn {
    border: none;
    outline: none;
    background: #ffffff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0d8bd1;
    padding: 9px 20px;
    margin-left: 30px;
    cursor: pointer;
}
.copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    color: #cccccc;
    padding: 30px 0;
}
.copyright span {
    margin-right: 5px;
}


@media screen and (max-width: 870px) {
    .footer__logo {
        width: 200px;
    }
    .footer__title {
        font-size: 15px;
        margin-bottom: 24px;
    }
    .footer__link {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .footer__column > a {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .footer__place {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .footer__social {
        justify-content: center;
    }

    .footer__social-item {
        width: 25px;
        height: 25px;
    }
    .copyright {
        font-size: 13px;
        padding: 20px 0;
    }
}

@media screen and (max-width: 530px) {
    .footer__column {
        display: none;
    }

    .footer__column:last-child {
        display: flex;
    }

}