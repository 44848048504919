.uploadBtn {
  padding: 10px 20px;
  color: white;
  background-color: #0d8bd1;
  border-radius: 20px;
  font-size: 14px;
  align-self: center;
  position: relative;
  top: -1px;
  border: none;
  cursor: pointer;
  outline: none;
}
.dropDown_placeholder.upload {
  cursor: auto;
  user-select: none;
  padding-right: 0;
  padding-top: 0;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 100vw;
  min-width: auto;
}
.dropDown_placeholder.upload > div {
  display: grid;
  grid-template-columns: auto 24px;

  max-width: calc(100% - 140px);
  gap: 20px;
}

.dropDown_placeholder.upload div svg path {
  transition: 0.2s stroke;
}
.dropDown_placeholder.upload div svg:hover path {
  stroke: #0d8bd1;
}
