.service-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  min-height: 300px;
  /* align-items: flex-start; */
}
.service-card_description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}
.service-card_description h4 {
  padding-bottom: 20px;
}
.service-card_description button {
  justify-self: end;
}
.service-card_deliverables {
  width: max-content;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #202020;
  border-radius: 10px;
  max-height: 300px;
  padding: 30px;
}
.service-card_deliverables div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.service-card_deliverables div > p {
  font-size: 15px;
  white-space: nowrap;
}
.service-card_deliverables div > b {
  padding-bottom: 5px;
}

@media (max-width: 1050px) {
  .service-card_deliverables {
    min-width: max-content;
  }
}
@media (max-width: 850px) {
  .service-card {
    flex-direction: column-reverse;
  }
  .service-card_deliverables {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  .service-card_deliverables b {
    grid-column: 1 / span 2;
  }
}
@media (max-width: 750px) {
  .service-card_deliverables {
    grid-template-columns: 1fr;
  }
  .service-card_deliverables b {
    grid-column: 1;
  }
  .service-card_deliverables div > p {
    font-size: 12px;
    white-space: nowrap;
  }
  .service-card_description button {
    justify-self: end;
    align-self: center;
  }
  .service-card_description div p {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .service-card_description button {
    padding: 10px 34px;
  }
}
