.fixed {
    position: sticky;
    top: 0;
    z-index: 997;
    width: 100%;
    background-color: #111213;
    box-shadow: 0px 0px 90px rgba(37, 29, 123, 0.1);
}
.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 0 !important;

    margin-bottom: 50px!important;
}
.header__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 520px;
}
.header__list-item {
    margin-right: 40px;
}
.header__list-item:last-child {
    margin-right: 0;
}
.header__list-link {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.header__list-link:hover {
    color: #0d8bd1;
}
.header__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.header__btn {
    background: #0d8bd1;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 18px;
    margin-right: 30px;
    cursor: pointer;
    transition: all .3s;
}
.header__btn:hover {
    box-shadow: 0px 0px 90px rgba(13, 139, 209, 0.15);
}
.header__language {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    margin-right: 8px;
    color: #999999;
}
.header__language:last-child {
    margin-right: 0;
}

.header__language-active {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    margin-right: 8px;
    color: #ffffff;
}
.header__language-active:last-child {
    margin-right: 0;
}



.header__burger {
    display: none;
}


@media screen and (max-width: 1033px) {

    .header__list {
        display: none;
    }

    .header__right {
        display: none;
    }
    .header__burger {
        cursor: pointer;
        display: block;
        margin-right: 20px;
    }

    .logo {
        margin-left: 20px;
    }

}